import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { graphql, navigate } from "gatsby"
import Img from "gatsby-image"
import '../projects.scss'
import locales from '../constants/locales'
import BoxRenderer from "../components/BoxRenderer"
import Topbar from '../components/Topbar'
import ogImage from '../assets/images/og-logo.jpg';

class Privacy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            scrollPosition: 0
        }
    }

    componentDidMount() {
        document.addEventListener('scroll', this.listenToScroll, {passive: true})
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.listenToScroll, {passive: true})
    }

    listenToScroll = () => {
        let winScroll = document.body.scrollTop || document.documentElement.scrollTop
        let height = document.documentElement.scrollHeight - document.documentElement.clientHeight  
        let scrolled = (winScroll / height) * 100

        this.setState({
            scrollPosition: scrolled
        })
    }

    render() {
        const { scrollPosition } = this.state;
        const { path, data, pageContext: { locale } } = this.props;
        const { 
            TITLE,
            DESCRIPTION,
            KEYWORDS,
            PRIVACY_POLICY
        } = locales[locale].textEntries

        return (
            <div>
                <Helmet>
                    <title>{ TITLE }</title>
                    <meta name="description" content={ DESCRIPTION } />
                    <meta name="keywords" content={ KEYWORDS } />
                    <meta name="robots" content="index, follow"/>
                    <meta http-equiv="content-language" content={ locale }/>
                    <meta http-equiv="content-type" content="text/html; charset=utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>

                    <meta property="og:type" content="website"/>
                    <meta property="og:url" content="https://www.nks-developer.com/"/>
                    <meta property="og:title" content={ TITLE }/>
                    <meta property="og:image" content={ ogImage }/>
                    <meta property="og:description" content={ DESCRIPTION }/>
                    <meta property="og:locale" content={ locale + '_' + locale.toUpperCase() }/>
                </Helmet>
                <Topbar scrollPosition={scrollPosition} voices={[]} locale={locale} path={path} languageUrl={data.privacyPages.path}>
                    <li onClick={() => {
                        document.body.style = '';
                        navigate('/' + ((!locales[locale].default) ? locale : ''))
                    }}>Home</li>
                </Topbar>
                <main>
                    <div className="ImageBackgroundContainer">
                        <Img fluid={data.background.childImageSharp.fluid} className="ImageBackground" alt="Fullstack Web Developer" />
                    </div>
                    <div className="ResponsiveContainer ProjectsPage">
                        <BoxRenderer>
                            <div className="LabelContainer">Privacy Policy</div>
                            <div dangerouslySetInnerHTML={{
                                __html: PRIVACY_POLICY
                            }}></div>
                        </BoxRenderer>
                    </div>
                </main>
            </div>
        )
    }
}

export default Privacy

export const query = graphql`
    query($path: String!) {
        privacyPages: sitePage(path: {regex: "/privacy/", ne: $path}) {
            path
        }

        background: file(relativePath: { eq: "background_privacy.jpg" }) {
            childImageSharp {
                fluid(traceSVG: {
                    color: "#27292e"
                    turnPolicy: TURNPOLICY_MINORITY
                    blackOnWhite: false
                }, maxWidth: 1200, maxHeight: 550) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
    }
`